@font-face {
	font-family: 'Notosans';
	font-weight: 400;
	font-style: normal;
	font-display: fallback;
	src: url('../fonts/NotoSans-Regular.woff') format('woff'),
	url('../fonts/NotoSans-Regular.ttf') format('ttf'),
	url('../fonts/NotoSans-Regular.eot') format('eot');
}

@font-face {
	font-family: 'Notosans';
	font-weight: 600;
	font-style: normal;
	font-display: fallback;
	src: url('../fonts//NotoSans-Medium.woff') format('woff'),
	url('../fonts/NotoSans-Medium.ttf') format('ttf'),
	url('../fonts/NotoSans-Medium.eot') format('eot');
}

@font-face {
	font-family: 'Notosans';
	font-weight: 800;
	font-style: normal;
	font-display: fallback;
	src: url('../fonts/NotoSans-Bold.woff') format('woff'),
	url('../fonts/NotoSans-Bold.ttf') format('ttf'),
	url('../fonts/NotoSans-Bold.eot') format('eot');
}