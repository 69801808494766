@import url(https://cdnjs.cloudflare.com/ajax/libs/meyer-reset/2.0/reset.css);
@import url(https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css);

* {
  box-sizing: border-box; 
  color: var(--dark);
  font-family:  'Notosans';
  font-size: 18px;
  line-height: 1.5;
}

p {
  word-break: keep-all;
}

a {
  text-decoration: none;
  color: var(--dark);
}

ul,
li {
  list-style: none;
}

:root{
  --navy : #373e5a;
  --brown1 : #f2e6d8;
  --brown2 : #e7cbb8;
  --brown3 : #ccad99;
  --brown4 : #8c776e;
  
  --main : rgb(0, 54, 150);
  --dark : #363C4D;
  --gray : rgb(231, 234, 242);
  --gradi1 : rgb(0, 191, 255);
  --gradi2 : #6490FF;
  --gradient : linear-gradient(45deg, var(--gradi1) 0%, var(--main) 100%);
  --admin : #00c0a6;
  --warning : #ff573d;
}

@keyframes gradi-ani {
  0%{background-position:0% 50%;}
  50%{background-position:100% 50%;}
  100%{background-position:0% 50%;}
}

::selection {
  background-color: var(--dark);
  color: white;
}

button {
  outline: none;
  border: transparent;
  background-color: transparent;
  cursor: pointer;
}

section {
  padding: 5vh 10vw;
}

@media(max-width:600px) {
  section {
    padding: 5vh 5vw;
  }
}